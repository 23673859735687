import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "user",
    initialState: {
        currentUser: [],
        loggedIn: false,
        loading: false,
        lastFetch: null,
        openLogin: false,
        role: null,
        userId: null,
        loginExpiresIn: null,

    },
    reducers: {
        userInfoReveived: (user, action) => {
            user.loading = false;
            user.loggedIn = true;
            user.currentUser = action.payload;
            //user.userId = action.payload[0].id;
            user.role = action.payload.role;
            // user.loginExpiresIn = action.payload[0].login_expires_in
        },
        userLogOut: (user, action) => {
            user.loggedIn = false;
            user.loading = false;
            user.role = null;
            //   user.userId = null;
            user.currentUser = [];
        },

    },
});

export const {
    userInfoReveived,
    userLogOut,
} = slice.actions;
export default slice.reducer;
