import React, { useState } from "react";
import { useRef } from "react";
import { motion, sync, useCycle } from "framer-motion";
import * as VSC from "react-icons/vsc";
import * as AI from "react-icons/ai";
import * as IM from "react-icons/im"

import Navbar from "./Navbar";



const MainMenu = () => {
    const [toggle, setToggle] = useState(false);



    const outerVariant = {
        hidden: {
            backgroundColor: toggle ? 'white' : 'transparent',
            width: toggle ? "25px" : 40,
        },
        visible: {
            backgroundColor: toggle ? 'white' : 'transparent',
            width: toggle ? "98vw" : 40,
            // height: 300
            when: "beforeChildren",
            transaction: {
                type: "spring",
                stiffness: 60
            }

        }
    }


    const menuVariant = {
        hidden: {
            backgroundColor: 'transparent',
            width: 0
        },
        visible: {
            backgroundColor: toggle ? 'white' : 'transparent',
            width: toggle ? '98vw' : 0,
            opacity: 1,
            staggerChildren: 0.2,
            transaction: {
                type: "spring",
                stiffness: 60
            }


        }
    }

    const menuItemsVariant = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transaction: {
                delay: 0.5
            }


        }
    }


    return (
        <>
            <motion.div
                initial={{ x: 500 }}
                animate={{ x: 0 }}
                transition={{
                    type: "spring",
                    stiffness: 60
                }}
                // style={{ display: 'flex', position: 'relative', right: 10, }}
                className="flex relative right-[10px]"

            >

                <motion.div
                    variants={outerVariant}
                    animate="visible"
                    initial="hidden"
                    // style={{ display: 'flex', position: 'absolute', right: 50, zIndex: 105, display: 'flex', justifyContent: 'end' }}
                    className="flex absolute right-[50px] z-50 justify-end "
                >

                    <motion.div
                        onClick={() => { setToggle(!toggle); }}
                        whileHover={{ scale: !toggle ? 1.1 : 1 }}
                        // style={{
                        //     width: 40, height: 40, borderRadius: '50%', backgroundColor: 'white',
                        //     display: 'flex', justifyContent: 'center', alignItems: 'center',
                        //     cursor: 'pointer',
                        // }}

                        className="w-[40px] h-[40px] rounded-full flex justify-center items-center cursor-pointer bg-white"

                    >

                        {
                            toggle ? <AI.AiOutlineClose color="black" size="30" /> : <VSC.VscMenu color="black" size="30" />
                        }
                    </motion.div >
                </motion.div>


                {toggle &&
                    <motion.div
                        variants={menuVariant}
                        animate="visible"
                        initial="hidden"
                        // style={{ position: 'absolute', top: 40, zIndex: 102, display: 'flex', flexDirection: 'column', width: '90%', paddingTop: "35px", paddingBottom: 15 }}
                        className="absolute top-[40px] z-50 flex flex-col w-[90%] pt-[35px] pb-[15px]"
                    >
                        <Navbar />
                    </motion.div>
                }

            </motion.div>
        </>)

}


export default MainMenu;