import React from "react";


function PageNotFound(props) {
  // const baseUrl = window.siteSettings.baseUrl;
  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12 d-flex justify-content-center">
            <div className="error-template ">
              <h1>Oops!</h1>
              <h2>Page Not Found</h2>
              <div className="error-details">
                Sorry, an error has occured, Requested page not found!
              </div>
              <div className="error-actions">
                <a href="/" className="btn btn-primary btn-lg">
                  <span className="glyphicon glyphicon-home"></span>
                  Take Me Home
                </a>
                <a
                  href="/"
                  className="btn btn-default btn-lg"
                >
                  <span className="glyphicon glyphicon-envelope"></span> Contact
                  Support
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
