import React, { useEffect } from 'react'
import Aos from "aos";
import 'aos/dist/aos.css';

import ReactPlayer from 'react-player'

import video1 from "../../assets/images/mansmoking.mp4";
import roy from "../../assets/images/roy.png";
import green from "../../assets/images/green.png";
import one from "../../assets/images/one.jpg";
import work from "../../assets/images/work.jpg";
import plantation from "../../assets/images/plantation.jpg";

import { useNavigate } from 'react-router-dom';

export default function Deals() {

    const navigate = useNavigate();
    useEffect(() => {
        const av = localStorage.getItem('ageAllowed');
        console.log(av);
        if (!av) {
            navigate('/verify')
        }
        Aos.init({ duration: 2000 });
    }, []);


    return (
        <>
            <div className='d-flex justify-content-between'>
                <div data-aos="fade-right" className='bg-orange d-flex justify-content-center align-items-cener' style={{ width: '70%' }}>
                    <img src={plantation} width="100%" />
                </div>
                <div data-aos="fade-left" className='bg-dark d-flex flex-column justify-content-center align-items-center' style={{ width: '50%' }}>

                    <div style={{ width: '80%', color: 'white' }}>
                        <h3>TOUCHED BY MORE THAN 100 HANDS</h3>
                        Once the plants have reached maturity, our farmers handpick the tobacco with care, ensuring its unique attributes are preserved.
                        Even with the great attention given to the crop, only a small portion of it will be deemed worthy of becoming LouieLeaf.
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-between'>
                <div data-aos="fade-right" className='bg-darkblack d-flex flex-column justify-content-center align-items-center' style={{ width: '50%' }}>
                    <div style={{ width: '80%', color: 'white' }}>
                        <h3>THE PRACTICE OF CURING</h3>
                        The leaves are hung in special wooden sheds where they are cured to express moisture and release natural sugars.
                        During this process, our agronomists monitor the tobacco closely, as this is when the flavor of LouieLeaf begins to take shape.


                    </div>
                </div>
                <div data-aos="fade-left" className='bg-orange d-flex' style={{ width: '50%' }}>
                    <img src={one} width="100%" />
                </div>

            </div>


            <div className='d-flex justify-content-between'>
                <div data-aos="fade-right" className='bg-orange d-flex' style={{ width: '50%' }}>
                    <img src={work} width="100%" />
                </div>
                <div data-aos="fade-left" className='bg-dark d-flex flex-column justify-content-center align-items-center' style={{ width: '50%' }}>

                    <div style={{ width: '80%', color: 'white' }}>
                        <h3>TOUCHED BY MORE THAN 100 HANDS</h3>
                        Once the plants have reached maturity, our farmers handpick the tobacco with care, ensuring its unique attributes are preserved.
                        Even with the great attention given to the crop, only a small portion of it will be deemed worthy of becoming LouieLeaf.
                    </div>
                </div>
            </div>


        </>
    )
}
