import * as VSC from "react-icons/vsc";
import * as AI from "react-icons/ai";
import * as IM from "react-icons/im"

import Styles from "./MainMenu.module.scss";
// import { BsSendFill } from "react-icons/bs";
// import { RiLoginCircleFill } from "react-icons/ri";


// export const NavItems = [
//     { title: 'HOME', link: '/', role: ['user'], icon: <AI.AiFillHome /> },

//     { title: 'ABOUT', link: '/about', role: ['user'], icon: <AI.AiFillInfoCircle /> },
//     { title: 'CONTACT', link: '/contact', role: ['user'], icon: <BsSendFill /> },
//     { title: 'LOGIN', link: '/login', role: ['user'], icon: <RiLoginCircleFill /> },
// ]
export const NavItems = [

    { id: 1, link: "/", title: "Home", icon: <VSC.VscWand className={Styles.menuListIcon} />, },
    {
        id: 1, title: "Shop Louie", icon: <VSC.VscWand className={Styles.menuListIcon} />, subMenu: [
            { id: 1, link: '/tobacco', title: 'Tobacco', subMenu: [{ link: '/rage', title: 'Rage' }, { link: '/cones', title: 'Cones' }, { link: '/wraps', title: 'Wraps' }] },
            { id: 2, link: '/merch', title: 'Merch' },
            { id: 3, link: '/accessories', title: 'Accessories' },
            { id: 4, link: '/drops', title: 'Drops' },
        ]
    },
    { id: 2, link: "/art", title: "Art Gallery", icon: <AI.AiFillTag className={Styles.menuListIcon} /> },
    // { id: 3, link: "/find", name: "Find Louie Leaf", icon: <IM.ImLocation className={Styles.menuListIcon} /> },
    { id: 4, link: "/subscription", title: "Subsriptions", icon: <AI.AiFillMail className={Styles.menuListIcon} /> },
    { id: 5, link: "/about", title: "About", icon: <VSC.VscWand className={Styles.menuListIcon} />, subMenu: [{ link: '/about', title: 'Who We are' }, { link: '/mission', title: 'Our Mission' }] },
]