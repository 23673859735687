import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ModalPop = ({ type, show, title, body, handleClose }) => {
  const close = () => {
    handleClose();
  };
  useEffect(() => { }, []);
  if (!show) return;
  return (
    <>
      <Modal
        size="lg"
        id="alertModal"
        className="pb-0 mb-0"
        show={show}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        onHide={close}
      >
        <Modal.Body className={"h-100 mb-0 rounded-0 alert alert-" + type}>
          {/* <Modal.Header closeButton> */}
          <div className="d-flex justify-content-center pt-5">
            <Modal.Title>
              <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </Modal.Title>
          </div>
          <div
            className="ps-5 pe-5 pt-1 d-flex justify-content-center"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
          <div className="d-flex justify-content-end mt-5">
            <Button className={"btn btn-sm btn-" + type} onClick={close}>
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalPop;
