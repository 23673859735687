import { Routes, Route, BrowserRouter, HashRouter, } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// import Header from "../components/Header";
import Footer from "../components/Footer";
import Location from "../pages/location";

import Deals from "../pages/deals";

import PageNotFound from "../pages/PageNotFound";

import Home from "../pages/home/";
import Ageverify from "../pages/Ageverify/";
import SocialLinks from "../components/SocialLinks";

const Router = ({ props }) => {

  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <>
          <div className="bg-black" style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>

            {/* <Header /> */}

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/verify" element={<Ageverify />} />
              <Route path="/find" element={<Location />} />
              <Route path="/deals" element={<Deals />} />

              <Route path="*" element={<PageNotFound />} />
            </Routes>
            <div
              className="bg-black"
            // style={{ marginTop: "auto" }}
            >
              {/* <div className="absolute bottom-0 right-0 left-0 bg-black"> */}
              <Footer />
              {/* </div> */}

            </div>
            <SocialLinks />
          </div>

        </>
      </BrowserRouter>

    </HelmetProvider>
  );
};

export default Router;
