import React, { useState, useRef, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import logoLouieleaf from "../../assets/images/louieleaf.png";

import Modal from "../../components/Modal";
import moment from "moment";
import Aos from "aos";
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";

import Styles from "./Ageveryfy.module.scss";

const Ageverify = (props) => {
  const navigate = useNavigate();
  const dateRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalType, setModalType] = useState("");



  const [yyyy, setYyyy] = useState("");
  const [dd, setDd] = useState("");
  const [mm, setMm] = useState("");

  function handleCloseModal() {
    setShowModal(false);
  }

  const handleYearInput = (e) => {
    //true if number
    if (!isNaN(e.target.value)) {
      setYyyy(e.target.value);
    }

  };


  const handleMonthInput = (e) => {
    //true if number
    if (!isNaN(e.target.value)) {
      setMm(e.target.value);
      if (e.target.value.length >= 2) {
        dateRef.current.focus();
        dateRef.current.select();
      }
    }

  };

  function handleDateInput(e) {
    //true if number
    if (!isNaN(e.target.value)) {
      setDd(e.target.value);
      if (e.target.value.length >= 2) {
        yearRef.current.focus();
        yearRef.current.select();
      }
    }

  }

  function verify() {
    let isError = false;
    let errorMsg = "<ul>";
    if (mm.length < 2) {
      isError = true;
      errorMsg += "<li>Enter two digit of your birthday month</li>";
    }
    if (dd.length < 2) {
      isError = true;
      errorMsg += "<li>Enter two digit of your birthday month</li>";
    }

    if (yyyy.length < 3) {
      isError = true;
      errorMsg += "<li>Enter two digit of your birthday month</li>";
    }
    errorMsg += "</ul>";
    if (isError) {
      setModalTitle("Please Correct Below Error(s)")
      setModalBody(errorMsg);
      setModalType('danger');
      setShowModal(true);
      return;
    }




    let dobs = new Date(yyyy + "-" + mm + "-" + dd);
    // let today = new Date();

    var years = moment().diff(dobs, "years", true);
    if (years < 21) {
      let tot = parseFloat(21 - years).toFixed(1);
      let parts = tot.split(".");
      let yrs = parseInt(parts[0]) > 1 ? parts[0] + " years " : " a year ";
      let mths =
        parseInt(parts[1]) > 1 ? " and " + parts[1] + " months" : "and a month";
      setModalTitle("SORRY, MUST BE 21 OR OLDER TO VIEW THIS SITE")
      setModalBody(
        "You can come back and visit this site after " + yrs + mths + "."
      );
      setModalType('danger')
      setShowModal(true);


    } else {
      localStorage.setItem("ageAllowed", true);
      navigate("/");
    }
  }

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      {showModal &&
        <Modal
          show={showModal}
          type={modalType}
          title={modalTitle}
          body={modalBody}
          handleClose={handleCloseModal}
        />
      }
      <div data-aos="fade-down"
        className="container-fluid bg-black d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >

        <div className="row d-flex justify-content-center">
          <div className="d-flex flex-column  align-items-center">
            <img data-aos="zoom-in"
              src={logo}
              alt="logo"
              style={{ height: "150px", width: "auto", }}

            />
            <img data-aos="zoom-in"
              src={logoLouieleaf}
              alt="louie leaf logo"
              style={{ height: "80px", width: "auto", marginBottom: 100 }}

            />
          </div>


          <div className="row d-flex justify-content-center">
            <div className="d-flex justify-content-center align-items-center w-100 flex-column text-light">
              <h4>ENTER YOUR </h4>
              <h2>DATE OF BIRTH</h2>
            </div>

            <div className="d-flex flex-row justify-content-center">
              <div className="mb-3  me-3 text-secondary lh-1 ">
                {mm.length > 0 ? <label htmlFor="floatingInput" className="mb-2">MONTH</label> : <div style={{ marginBottom: 25 }}></div>}
                <input ref={monthRef}
                  name="field-1"
                  className="form-control bg-transparent border-secondary text-light"
                  id="monthInput"
                  maxLength={2}
                  type="text"
                  placeholder="MONTH"
                  value={mm}
                  onChange={(e) => {
                    handleMonthInput(e);
                  }}
                  style={{ height: "50px", width: "100px" }}
                />

              </div>
              <div className="mb-3  me-3 text-secondary lh-1 ">
                {dd.length > 0 ? <label htmlFor="floatingInput" className="mb-2">DATE</label> : <div style={{ marginBottom: 25 }}></div>}
                <input ref={dateRef}
                  name="field-2"
                  maxLength={2}
                  value={dd}
                  onChange={(e) => {
                    handleDateInput(e);
                  }}
                  className="form-control bg-transparent border-secondary text-light text-center"
                  id="monthInput"
                  type="number"
                  placeholder="DATE"
                  style={{ height: "50px", width: "100px" }}
                />

              </div>
              <div className="mb-3  me-3 text-secondary lh-1 ">
                {yyyy.length > 0 ? <label htmlFor="floatingInput" className="mb-2">YEAR</label> : <div style={{ marginBottom: 25 }}></div>}
                <input ref={yearRef}
                  name="field-3"
                  className="form-control bg-transparent border-secondary text-light text-center"
                  id="monthInput"
                  maxLength={4}
                  value={yyyy}
                  onChange={(e) => {
                    handleYearInput(e);
                  }}
                  type="text"
                  placeholder="YEAR"
                  style={{ height: "50px", width: "100px", borderColor: 'red' }}
                />

              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-5">
          <button
            onClick={verify}
            className={Styles.enterBtn}

          >
            ENTER LOUIE LEAF SITE
          </button>
        </div>
        <div className="row d-flex justify-content-center mt-5 " data-aos-delay="300" data-aos="zoom-in">
          <div
            className="bg-light d-flex flex-column align-items-center p-2 position-relative"
            style={{ height: "90px", width: "350px", color: "#004242" }}
          >
            <div data-aos-delay="350" data-aos="zoom-in-right" style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', width: 35, height: 35, borderRadius: '50%',
              position: 'absolute', top: '-10px', right: '-15px', backgroundColor: 'red', color: 'white', border: '2px solid white'
            }}>
              <b>21<sup>+</sup></b>
            </div>
            <h5 className="p-0 m-0">SURGEON GENERAL WARNING:</h5>
            Cigar Smoking Can Cause Lung
            <br />
            Cancer And Heart Desease.
          </div>
        </div>
      </div>

    </>
  );
};

export default Ageverify;
