import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import * as FA from "react-icons/fa";
import Styles from "./SocialLinks.module.scss";
import { motion, AnimatePresence } from 'framer-motion'


export default function SocialLinks() {
    const location = useLocation();
    const isVerify = location.pathname.toString().includes("verify");
    const [showSocial, setShowSocial] = useState(false)
    return (
        <>
            {!isVerify &&
                < div
                    style={{
                        position: "fixed",
                        right: "0",
                        top: 0,
                        bottom: 0,


                    }
                    }
                >
                    <div
                        className={Styles.wrapper}

                    >  <AnimatePresence>
                            <motion.div
                                layout
                                className='d-flex justify-content-center align-items-center ms-2'>
                                {
                                    showSocial &&
                                    <motion.div

                                        // initial={{ x: '100', opacity: 0 }}
                                        // animate={{ x: 0, opacity: 1 }}
                                        // exit={{ x: "100", }}
                                        // transition={{
                                        //     duration: 0.5, ease: "easeOut"}} 
                                        className={Styles.iconWrapper}>
                                        <FA.FaFacebookF className={`${Styles.socialIcons} ${Styles.facebookIcon}`} />
                                        <FA.FaInstagram className={`${Styles.socialIcons} ${Styles.instagramIcon}`} />
                                        <FA.FaTwitter className={`${Styles.socialIcons} ${Styles.twitterIcon}`} />
                                    </motion.div>
                                }
                                <div className={`${showSocial ? Styles.iconLinkWrapper : Styles.iconLinkWrapperRounded}`}>
                                    <FA.FaShareAlt className='m-2 text-light' onClick={() => setShowSocial(!showSocial)} />
                                </div>

                            </motion.div>
                        </AnimatePresence>

                    </div>
                </div >
            }
        </>
    )
}
