import React from "react";

import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const isVerify = location.pathname.toString().includes("verify");
  return (<>
    {!isVerify &&
      <div className="bg-darkblack md:h-[150px]">
        <div className="flex flex-col justify-between items-center pl-5 pr-5 relative md:flex-row" >
          <div data-aos="fade-up" className="flex  justify-evenly items-center text-white md:w-[400px] md:h-[150px] " >
            social
          </div>
          <div>
            <div data-aos="fade-up" className="flex justify-between items-center text-white text-xs "
            //  style={{ color: 'white', width: 400, height: 150 }}
            >
              <div className="m-2 ">Privacy </div>
              <div className="m-2">Terms</div>
              <div className="m-2">Contact</div>
            </div>
          </div>

          <div data-aos="flip-down" className="d-flex align-items-center bg-darkblack" style={{ height: 150 }}>
            <div className="row d-flex justify-content-center mt-5 border border-2 border-dark" data-aos="fade-in">
              <div
                className="bg-light flex flex-col items-center p-2 relative h-[70px] w-[280px] md:h-[90px] md:w-[350px]"
              // style={{ height: "90px", width: "350px", color: "#000" }}
              >
                <div data-aos-delay="350" data-aos="fade-in"
                  className="flex justify-center items-center w-[25px] h-[25px] rounded-full absolute top-[-10px] right-[-15px] bg-red text-white border-solid border-white border-[2px] md:w-[35px] md:h-[35px]"
                >
                  <span className="text-xs md:text-sm">21<sup>+</sup></span>
                </div>
                <h5 className="p-0 m-0 text-sm md:text-lg">SURGEON GENERAL WARNING:</h5>
                <div className="text-xs md:text-sm">
                  Cigar Smoking Can Cause Lung
                  <br />
                  Cancer And Heart Desease.
                </div>

              </div>
            </div>
          </div>

        </div>
        <div className="bg-darkblack text-white text-center text-xs md:text-sm">
          copyright&copy; Louie Leaf
        </div>
      </div>
    }
  </>
  )
}

export default Footer;
