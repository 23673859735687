import React, { useState } from 'react'
import axios from 'axios'
import GoogleMapReact from 'google-map-react';
import { BsPinFill } from 'react-icons/bs'
import { calculateDistance } from '../../utils/util'

const AnyReactComponent = ({ text, color }) => <div style={{ width: '500px!important' }}><BsPinFill color={color} size="50" />
    <div style={{ position: 'absolute', zIndex: 1005, backgroundColor: 'yellow', padding: 5, color: 'black', borderRadius: 5 }}>{text}</div></div>;

export default function Location() {
    const apiKey = "AIzaSyAQgUPsuZqroPLYCPgwFUt7FR2mh9uy9CY"
    const [zipcode, setZipcode] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [distance, setDistance] = useState('')
    const [address, setAddress] = useState('');

    const getLocation = async () => {

        try {
            if (zipcode.length === 5) {
                const url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + zipcode + "&key=" + apiKey;
                const res = await axios.get(url);

                const lat = res.data.results[0].geometry['location'].lat;
                const lon = res.data.results[0].geometry['location'].lng;

                setAddress(res.data.results[0]["formatted_address"])
                setLatitude(lat);
                setLongitude(lon);
                getDistance(lat, lon)
            } else {
                console.log("Error")
            }
        } catch (error) {
            setLatitude(null);
            setLongitude(null);
        }


    }

    function getDistance(lat, lon) {

        console.log("calculating ...")
        const dist = calculateDistance(34.2249609, -118.6556478, lat, lon);
        setDistance(dist);
        console.log("DIST", dist)

    }
    /*
    Latitude: 34.2249609
    Longitude: -118.6556478
    */

    const defaultProps = {
        center: {
            lat: 34.2249609,
            lng: -118.6556478
        },
        zoom: 12
    };


    return (
        <>
            <h4 className='text-dark'>FIND LOUIE LEAF</h4>
            <div className="row">
                <div className='col-lg-7 border border-dark p-2 ms-3'>

                    map
                </div>
                <div className='col-lg-4 p2'>
                    address
                </div>

            </div>

        </>
    )
}
