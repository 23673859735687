import React from "react";
import { NavItems } from "./NavItems";
import Dropdown from "../dropdown/";

function NavLinks() {
  return (
    <div className="flex items-center ">
      <ul className="flex flex-col gap-10 items-center md:flex-row">
        {NavItems.map((link, ind) =>
          link.subMenu ? (
            <Dropdown key={ind} title={link.title} submenu={link.subMenu} />
          ) : (
            <li className="font-semibold uppercase" key={ind}>
              {link.title}
            </li>
          )
        )}
      </ul>
    </div>
  );
}

export default NavLinks;
