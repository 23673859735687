import React from "react";
import { Link } from "react-router-dom";
import NavLinks from "./NavLinks";
import { NavItems } from "./NavItems";
function Navbar() {
  return (
    <nav>
      <div className="flex items-center font-medium justify-center">
        <NavLinks />
      </div>
    </nav>
  );
}

export default Navbar;
