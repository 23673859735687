import React, { useEffect } from "react";
import Router from "./router";

// import "./styles/bgColors.css";
// import "./styles/textColors.css";

import "./App.css";




function App() {

  return (
    <div >
      <Router />
    </div>
  )
}

export default App;
