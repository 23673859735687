// import { reduceRight } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Styles from "./Header.module.scss"
import MainMenu from "../MainMenu"
import logo from "../../assets/images/logo.png";
import logoLouieleaf from "../../assets/images/louieleaf.png";
import { FaFacebookF, FaTwitter, FaPinterest, FaEnvelope, FaQuestionCircle } from "react-icons/fa"

import { FiSend } from "react-icons/fi"
import TopHeader from "./TopHeader";

import Dropdown from "../dropdown";


export const Header = () => {
  const location = useLocation();
  const [headerClass, setHeaderClass] = useState('header');

  const isVerify = location.pathname.toString().includes("verify");

  const listenScrollEvent = (event) => {

    if (window.scrollY < 20) {

      return setHeaderClass("header")
    } else if (window.scrollY > 19) {
      return setHeaderClass("stickyHeader")
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);


  return <>
    {!isVerify &&
      <>
        <div className="flex flex-column absolute w-[100%] top-0 right-0 left-0 z-50 bg-black/[0.2]">

          {/* <TopHeader /> */}

          <div className={" flex items-center justify-between pl-2 " + headerClass}
          >
            <div className="flex flex-column ">
              <img
                src={logo}
                alt="louie leaf logo"
                // style={{ height: "80px", width: "auto", }}
                className="h-[80px] w-auto"

              />
              <div className="text-white">
                LOUIE LEAF
              </div>
              <MainMenu />
            </div>


            {/* <div>
          <img
            src={logoLouieleaf}
            alt="louie leaf logo"
            style={{ height: "80px", width: "auto", }}
          />
        </div> */}


            <div></div>
            <button className="bg-white text-xs text-black py-2 px-2 rounded-full mr-3 md:text-sm" >
              <Dropdown title=" DEALERS LOGIN" submenu={[{ title: 'Customer', link: '/login' }, { title: 'Dealers', link: '/dealers' }]} submenuTopMargin="15px" />
            </button>

          </div >

          {/* <div style={{ marginBottom: 150 }}></div> */}
        </div>
      </>


    }
  </>;
};
export default Header;
