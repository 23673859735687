import React, { useEffect } from 'react'
import Aos from "aos";
import 'aos/dist/aos.css';

import ReactPlayer from 'react-player'

import video1 from "../../assets/videos/cigarsmoke.mp4";
import roy from "../../assets/images/roy.png";
import man from "../../assets/images/man.jpg";

import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';


export default function Home() {

    const navigate = useNavigate();
    useEffect(() => {
        const av = localStorage.getItem('ageAllowed');
        console.log(av);
        if (!av) {
            navigate('/verify')
        }
        Aos.init({ duration: 2000 });
    }, []);


    return (
        <div>
            <div className='relative bg-black'>
                <Header />
                <ReactPlayer style={{ padding: 0, margin: 0 }} url={video1}
                    loop={true} muted={true} width={'100%'} height="auto"
                    playsinline={true} playing={true} data-aos="flip-left" />

                <div
                    data-aos="zoom-in-up"
                    className='absolute top-[250px] right-[25px] bg-black/[0.5] py-[15px] px-[95px]'
                // style={{ position: 'absolute', top: 50, right: 25, backgroundColor: 'rgba(0,0,0,0.5', padding: "15px 95px" }}
                >
                    <div
                        className='text-[25px] font-bold text-red md:text-[45px] '

                    >THE BRAND</div>
                    <div className='flex justify-end mt-[-15px]'>
                        <div
                            className='text-[15px]  text-red  md:font-medium md:text-[20px]'
                        // style={{ fontSize: 20, fontWeight: 500, color: 'red' }}
                        >TASTE & SMELL</div>
                    </div>


                </div>

            </div>
            {/*
            <div className='p0 m-0 position-relative'>
                <img src={green} style={{ width: '100%', height: 'auto' }} />
                <div style={{
                    zIndex: 12, position: 'absolute', top: '50%', left: '50%',
                    backgroundColor: 'rgba(0,0,0,0.6', color: 'white', height: 100, width: 450, display: 'flex',
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <h1 style={{ fontFamily: "'Montserrat', sans-serif", fontWeight: "100", fontSize: "40px" }}>This is Life ...</h1>
                </div>
            </div>
            <div data-aos="flip-left" data-aos-duration="3000"
                style={{ height: '500px', width: '100%', backgroundColor: 'green' }}
                className='d-flex justify-content-center align-items-center text-light mt-5'>

            </div>

           */}

            <div data-aos="zoom-in-up" style={{ width: '100%', backgroundColor: '#c0c0c0' }} className='d-flex justify-content align-items-center text-light'>
                <img src={man} style={{ width: '100%', height: 'auto' }} />
            </div>


        </div>
    )
}
